<template lang="html">
  <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
    <div class="box">
      <article class="media">
        <div class="media-left">
          <figure class="image" :class="data.type === ArticleType.Medium ? 'is-16by9' : 'height-70'">
            <img :src="data.image" :alt="data.name + ' logo'"/>
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <p>
              <strong class="is-size-5">{{data.title}}</strong>
              <br>
              <span class="desc">{{data.description}}</span>
            </p>
          </div>
          <div class="tags">
            <span class="tag" :class="tag === 'unpublished' || tag === 'in development' ?  'is-gl-orange' : 'is-gl-green'"
                  v-for="tag in data.categories" v-bind:key="tag">
              {{tag}}
            </span>
          </div>
          <div class="date">
            <p>
              <small class="is-size-7">{{ formatdate(data.pubDate) }}</small>
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item" v-if="data.type === ArticleType.Medium" aria-label="medium" :href="data.link" target="_blank">
                <span class="icon is-small">
                  <i class="fas fa-eye" aria-hidden="true"></i>
                </span>
                Continue on Medium
              </a>
              <a class="level-item" v-if="data.type === ArticleType.Blog" aria-label="blog" :href="data.link" target="_blank">
                <span class="icon is-small">
                  <i class="fas fa-eye" aria-hidden="true"></i>
                </span>
                Visit Blog
              </a>
            </div>
          </nav>
        </div>
      </article>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import { ArticleType } from '@/models/article'

export default {
  name: 'Article',
  props: {
    data: Object
  },
  data () {
    return {
      ArticleType
    }
  },
  methods: {
    formatdate: function (date: Date) {
      return moment(date).format('MMMM YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.image {
  margin-bottom: 0.5rem;
}
.height-70 {
  height: 70px;
  img {
    height: 100%;
    width: auto;
  }
}

.media-content {
  .content {
    margin-bottom: 1rem;
    .desc {
       color: $grey;
    }
  }
  .date {
    color: $grey;
    margin-bottom: 1rem;
  }
}

.tags:not(:last-child) {
    margin-bottom: 0;
}
</style>
