
import moment from 'moment'
import { ArticleType } from '@/models/article'

export default {
  name: 'Article',
  props: {
    data: Object
  },
  data () {
    return {
      ArticleType
    }
  },
  methods: {
    formatdate: function (date: Date) {
      return moment(date).format('MMMM YYYY')
    }
  }
}
