<template>
  <div id="news" class="panel">
    <h1 class="title is-spaced is-size-3-desktop is-size-4-mobile">My articles</h1>
    <h2 class="subtitle is-size-5-desktop">Want to discuss any of these topics? <a href="mailto:geoffrey@lalloue.fr">Email me</a>.</h2>
    <div id="articles" class="container is-fluid">
      <pulse-loader :loading="!blogLoaded && !mediumLoaded" :color="spinnerColor"></pulse-loader>
      <div v-if="blogLoaded || mediumLoaded" class="columns is-multiline is-mobile">
        <Article  v-for="item in orderedItems(articles)" v-bind:key="item"
                  :data="item" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">

#articles {
  text-align: left;
  padding: 2rem 0 4rem;
  .v-spinner {
    text-align: center;
  }
}

@include until($tablet) {
  .projects {
      padding-top: 4rem;
  }
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Article from '@/components/Article.vue'
import Parser from 'rss-parser'
import { ArticleType, ArticleClass } from '@/models/article'
import axios from 'axios'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Options({
  components: {
    Article,
    PulseLoader
  }
})
export default class News extends Vue {
  articles!: ArticleClass[];
  blogLoaded = false;
  mediumLoaded = false;

  data () {
    return {
      articles: [],
      blogLoaded: false,
      mediumLoaded: false,
      spinnerColor: '#1BA1E2'
    }
  }

  async created (): Promise<void> {
    this.blogLoaded = false
    this.mediumLoaded = false

    // ARTICLES FROM BLOG : http://lalloue.fr/blog/
    axios.get('blog-articles.json').then(response => {
      // eslint-disable-next-line
      response.data.articles.forEach((item: any) => {
        const imageUrl = item.image || this.getImgUrl('article-default-img.png')
        const pubDate = new Date(item.pubDate)
        const article = new ArticleClass(ArticleType.Blog, item.id, item.title, item.link, pubDate, imageUrl, item.description, item.tags)
        this.articles.push(article)
        this.blogLoaded = true
      })
    })

    // ARTICLES FROM MEDIUM.COM : https://medium.com/@glalloue
    const mediumUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@glalloue'
    axios.get(mediumUrl).then(response => {
      // eslint-disable-next-line
      response.data.items.forEach((item: any) => {
        if (item.guid && item.title && item.link && item.pubDate) {
          const imageUrl = (item.content && this.extractImgUrl(item.content)) || this.getImgUrl('article-default-img.png')
          const pubDate = new Date(item.pubDate)
          const desc = item.contentSnippet && item.contentSnippet.substring(0, item.contentSnippet.indexOf('Continue reading on Medium »'))
          const article = new ArticleClass(ArticleType.Medium, item.guid, item.title, item.link, pubDate, imageUrl, desc, item.categories)
          this.articles.push(article)
          this.mediumLoaded = true
        }
      })
    })
  }

  orderedItems (items: ArticleClass[]) {
    // Set slice() to avoid to generate an infinite loop!
    return items.slice().sort(function (a, b) {
      return b.pubDate.getTime() - a.pubDate.getTime()
    })
  }

  extractImgUrl (content: string) {
    const regex = /<img.*?src=['"](.*?)['"]/
    const url = regex.exec(content)
    return url && url[1]
  }

  getImgUrl (imageName: string) {
    let imgUrl
    try {
      imgUrl = require('../assets/images/' + imageName)
    } catch (e) {
      imgUrl = require('../assets/images/article-default-img.png')
    }
    return imgUrl
  }
}
</script>
