export enum ArticleType {
  Medium,
  Blog
}

export class ArticleClass {
    // fields
    guid: string;
    type: ArticleType;
    title: string;
    description?: string;
    link: string;
    image?: string;
    pubDate: Date;
    categories?: string[];

    constructor (type: ArticleType, guid: string, title: string, link: string, pubDate: Date, image?: string, contentSnippet?: string, categories?: string[]) {
      this.guid = guid
      this.type = type
      this.title = title
      this.link = link
      this.image = image
      this.description = contentSnippet
      this.pubDate = pubDate
      this.categories = categories
    }
}
